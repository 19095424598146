<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Registros por página"
          >
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-form-group>
        </b-col>

        <!-- New movement -->
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-right justify-content-end mb-1 mb-md-0 topTable"
        >
          <div class="d-flex align-items-right justify-content-end">
            <b-button
              @click="showModal"
              :disabled="saveButtonStatus"
            >
              <div v-if="!saveButtonStatus">Nuevo gasto</div>
              <div v-if="saveButtonStatus">
                <b-spinner small v-if="saveButtonStatus"/>
                &nbsp; Guardando gasto...
              </div>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>
      <!-- :items="fetchInvoices" -->
    <b-overlay
      :show="tableOverlay"
      rounded="sm"
    >
      <div id="main-table">
        <b-table
          hover
          responsive
          id="main-table"
          :per-page="perPage"
          :current-page="currentPage"
          :items="data"
          @row-clicked="showEditModal($event.id)"

          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"

          ref="refInvoiceListTable"
          :fields="tableColumns"
          primary-key="name"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No hay registros"
          class="position-relative"
        >

          <!-- <template #head(invoiceStatus)>
            <feather-icon
              icon="TrendingUpIcon"
              class="mx-auto"
            />
          </template> -->

          <!-- Column: Status -->
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>

        </b-table>
      </div>
    </b-overlay>
    <br>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <!-- ADD MODAL -->
    <b-modal
      id="addModal"
      title="Registrar gasto"
      ok-title="Registrar"
      ocancelk-title="Cancelar"
      cancel-variant="outline-secondary"
      @ok="registerExpense($event)"
      @cancel="resetModal()"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-overlay
        :show="modalOverlay"
        rounded="sm"
      >
        <!-- Nombre -->
        <b-form-group
          label="Nombre"
          label-for="nombre"
        >
          <b-form-input
            autocomplete="off"
            @keypress="handleEnterKey($event, 'add')"
            id="nombre-gasto"
            placeholder="Ingresa el nombre del gasto"
            v-model="name"
          />
        </b-form-group>
        <b-alert
          variant="danger"
          show
          v-model="alert"
        >
          <h4 class="alert-heading">
            Faltan datos
          </h4>
          <div class="alert-body font-medium-2">
            <p>Se requiere ingresar un nombre.</p>
          </div>
        </b-alert>
      </b-overlay>
    </b-modal>
    <!-- EDIT MODAL -->
    <b-modal
      id="editModal"
      title="Editar gasto"
      ok-title="Editar"
      ocancelk-title="Cancelar"
      cancel-variant="outline-secondary"
      @ok="updateExpense($event)"
      @cancel="resetModal()"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-overlay
        :show="modalOverlay"
        rounded="sm"
      >
        <!-- Nombre -->
        <b-form-group
          label="Nombre"
          label-for="nombre"
        >
          <b-form-input
            autocomplete="off"
            @keypress="handleEnterKey($event, 'update')"
            id="nombre-gasto"
            placeholder="Ingresa el nombre del gasto"
            v-model="name"
          />
        </b-form-group>
        <b-alert
          variant="danger"
          show
          v-model="alert"
        >
          <h4 class="alert-heading">
            Faltan datos
          </h4>
          <div class="alert-body font-medium-2">
            <p>Se requiere ingresar un nombre.</p>
          </div>
        </b-alert>
      </b-overlay> 
    </b-modal>
  </b-card>

</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/customHelios.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  width: 80px;
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'

// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, 
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle,
BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
BPagination, BTooltip, VBModal, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {
    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BCardBody,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    VBModal,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Table
      tableColumns: [
        { key: 'name', label: 'Nombre', sortable: true },
      ],
      perPage: 5,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      onFiltered: [],

      data: [],
      saveButtonStatus: false,
      modalOverlay: true,
      tableOverlay: true,

      // Modal
      alert: false,
      name: null,
      id: null,
      }
  },
  created() {
    this.getExpenses();
  },
  methods: {
    getExpenses() {
      this.$axios.get(this.$services + 'expenses/get_expenses', {
        params: {
          type: 'COMMISSIONS'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.data = res.data.data;
          this.totalRows = res.data.data.length;
        } else
        throw 'Error al obtener gastos.';
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      }).finally(() => {
        this.tableOverlay = false;
      });
    },
    getHistoryItemByItem(value) {
      this.$axios.get(this.$services + 'expenses/get_expenses', {
        params: {
          idExpense: value,
          type: 'COMMISSIONS'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          let data = res.data.data[0];
          this.name = data.name;
          this.id = data.id;
        } else
        throw 'Error al obtener dato.';
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      }).finally(() => {
        this.modalOverlay = false;
      });
    },
    registerExpense(bvModalEvt) {
      if (this.name == null || !(/[\s\S]*\S[\s\S]*/.test(this.name))) { // Fail (vacío o sólo espacios en blanco)
        this.alert = true;
        bvModalEvt.preventDefault();
        return;
      } else { // Success
        this.$bvModal.hide('addModal');
        this.alert = false;
        this.tableOverlay = true;
        this.saveButtonStatus = true;
        this.$axios.post(this.$services + 'expenses/create_expenses', {
          name: String(this.name),
        }).then(res => {
          this.$refreshToken(res.headers['x-access-token']);
          if (!res.data.error) {
            this.getExpenses();
            this.resetModal();
            this.showToast('success', '👋  ¡Gasto guardado con éxito!');
          } else 
          throw 'No se pudo guardar el gasto.';
        }).catch(error => {
          if (typeof error != 'string') {
            this.$verifyToken(error.response.data.data);
          } this.showToast('danger', String(error));
        }).finally(() => {
          this.saveButtonStatus = false;
          this.tableOverlay = false;
        });
      }
    },
    updateExpense(bvModalEvt) {
      if (this.name == null || !(/[\s\S]*\S[\s\S]*/.test(this.name))) { // Fail
        this.alert = true;
        bvModalEvt.preventDefault();
        return;
      } else { // Success
        this.$bvModal.hide('editModal');
        this.alert = false;
        this.tableOverlay = true;
        this.saveButtonStatus = true;
        this.$axios.post(this.$services + 'expenses/update_expenses', {
          id: String(this.id),
          name: String(this.name),
        }).then(res => {
          this.$refreshToken(res.headers['x-access-token']);
          if (!res.data.error) {
            this.getExpenses();
            this.showToast('success', '👋  ¡Gasto actualizado con éxito!');
          } else
          throw 'No se pudo actualizar el gasto.';
        }).catch(error => {
          if (typeof error != 'string') {
            this.$verifyToken(error.response.data.data);
          } this.showToast('danger', String(error));
        }).finally(() => {
          this.saveButtonStatus = false;
          this.tableOverlay = false;
          this.resetModal();
        });
      }
    },
    resetModal() {
      this.alert = false;
      this.name = null;
    },
    showModal() {
      this.$bvModal.show('addModal');
      this.modalOverlay = false;
    },
    showEditModal(id) {
      this.getHistoryItemByItem(id);
      this.$bvModal.show('editModal');
      this.modalOverlay = true;
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    // changeTableSize() {  // Deshabilitado por el momento
    //   let height = this.perPage * 3 + 3;  // this.perPage * alturaFila + alturaHeader
    //   document.getElementById('main-table').style.height = String(height) + 'em';
    // },
    handleEnterKey(event, type) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode == 13) { // 13 == Enter
        (type === 'add' ? this.registerExpense(event) : this.updateExpense(event));
      }
    },
  },
  computed: {
  },
}
</script>
